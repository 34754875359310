///////////////////////////////////////////////
//     							             //
//  Program: TowerService.js                 //
//  Application: Services                    //
//  Option: Used for tower data              //
//  Developer: Ashish Kumar                  //
//  Date: 2022-01-24                         //
//                                           //
///////////////////////////////////////////////

import ApiService from "./ApiService";

class ReportService {
  getRent(queryString) {
    let endPoint = "unit-rent" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createRent(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;
    let endPoint = "unit-rent";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getPropertyEarningReports(queryString) {
    // let endPoint = "occupancy-report/" + slug + (queryString !== "" ? "?" + queryString : "");
    let endPoint =
      "occupancy-report" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  deleteRent(slug) {
    let endPoint = "unit-rent/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  exportDataFilewise(data) {
    let endPoint = "unit-rent-export";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  exportEarningReport(queryString) {
    // let propertySlug = data.property_slug;
    // let endPoint = "occupancy-report/" + propertySlug + (queryString !== "" ? "?" + queryString : "");
    let endPoint =
      "occupancy-report" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  importData(data) {
    let endPoint = "unit-rent-import";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  getPropertyPayOutReports(queryString) {
    let endPoint =
      "property-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportPropertyPayOutReport(queryString) {
    //let propertySlug = data.property_slug;
    let endPoint =
      "property-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getPropertyPayOutReportsByUnit(queryString) {
    let endPoint =
      "property-unit-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportPropertyPayOutReportByUnit(queryString) {
    // let propertySlug = data.property_slug;
    let endPoint =
      "property-unit-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getPropertyPayOutReportsByReservation(queryString) {
    let endPoint =
      "property-reservation-payout" +
      (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportPropertyPayOutReportByReservation(queryString) {
    // let propertySlug = data.property_slug;
    let endPoint =
      "property-reservation-payout" +
      (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentPayOutReports(queryString) {
    let endPoint =
      "resident-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportResidentPayOutReport(queryString) {
    // let propertySlug = data.property_slug;
    let endPoint =
      "resident-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentPayOutReportsList(slug, queryString) {
    let endPoint =
      "resident-payout-summary?property_slug=" +
      slug +
      (queryString !== "" ? "&" + queryString : "");
    let addditionalHeaderData = {};
    let postData = { ispayout: 1 };
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentPayOutReportsCreate(slug, queryString) {
    let endPoint =
      "create-resident-payout?property_slug=" +
      slug +
      (queryString !== "" ? "" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentPayOutReportsEdit(slug, queryString) {
    let endPoint =
      "edit-resident-payout?property_slug=" +
      slug +
      (queryString !== "" ? "" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentPayOutReportsView(slug, queryString) {
    let endPoint =
      "view-resident-payout?property_slug=" +
      slug +
      (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createResidentPayout(data) {
    // Delete data which we don't want to send
    delete data.errors;
    //delete data.input;
    let endPoint = "create-resident-payout";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateResidentPayout(data) {
    delete data.errors;
    //delete data.input;
    let endPoint = "edit-resident-payout";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  showPayOutReport(data, queryString) {
    let propertySlug = data.property_slug;
    let endPoint =
      "payout-show/" +
      propertySlug +
      (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getPropertyHomeSharingReports(queryString) {
    let endPoint =
      "homesharing-report" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportHomeSharingReport(queryString) {
    // let propertySlug = data.property_slug;
    let endPoint =
      "homesharing-report" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getOhEarningReports(queryString) {
    let endPoint =
      "oh-earning-report" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportOhEarningReport(queryString) {
    let endPoint = "oh-earning-report" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getPropertyTaxReport(queryString) {
    let endPoint = "property-tax" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportPropertyTaxReport(queryString) {
    let endPoint = "property-tax" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getTopPerformerReport(queryString) {
    let endPoint =
      "top-perform-report" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportTopPerformerReport(queryString) {
    // let propertySlug = data.property_slug;
    let endPoint =
      "top-perform-report" + (queryString !== "" ? "?" + queryString : "");

    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentTopUpList(queryString) {
    let endPoint =
      "resident-topup" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getRUPayoutDetails(queryString) {
    let endPoint = "ru-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportRUPayoutDetails(queryString) {
    // let propertySlug = data.property_slug;
    let endPoint = "ru-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getPropertyPayOutReportsList(slug, queryString) {
    let endPoint =
      "property-payout-summary?property_slug=" +
      slug +
      (queryString !== "" ? "&" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {
      ispayout: 1,
    };
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  gePropertyPayOutReportsCreate(slug, queryString) {
    let endPoint =
      "create-payout-property?property_slug=" +
      slug +
      (queryString !== "" ? "" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getPropertyPayOutReportsEdit(slug, queryString) {
    let endPoint =
      "edit-property-payout?property_slug=" +
      slug +
      (queryString !== "" ? "" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getPropertyPayOutReportsView(queryString) {
    let endPoint =
      "view-property-payout" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createPropertyPayout(data) {
    // Delete data which we don't want to send
    delete data.errors;
    //delete data.input;
    let endPoint = "create-payout-property";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateProperrtyPayout(data) {
    delete data.errors;
    //delete data.input;
    let endPoint = "edit-property-payout";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  showPropertyPayOutReport(data, queryString) {
    let propertySlug = data.property_slug;
    let endPoint =
      "property-payout-show/" +
      propertySlug +
      (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getDepositeReport(queryString) {
    let endPoint =
      "guest-form-deoposite-report" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  geWavioPayOutReportsCreate(slug, queryString) {
    let endPoint =
      "create-wavio-payout?property_slug=" +
      slug +
      (queryString !== "" ? "" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createWavioPayout(data) {
    // Delete data which we don't want to send
    delete data.errors;
    //delete data.input;
    let endPoint = "create-wavio-payout";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  getWavioSummaryReportsList(queryString) {
    let endPoint =
      "get-wavio-payout-summary" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getWavioSummaryReportsDetail(queryString,slug) {
    let endPoint =
      "get-wavio-payout-summary-details/" +slug+ (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getMonthlyInvestorReports(queryString) {
    let endPoint =
      "monthly-investor-report" + (queryString !== "" ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
}

export default new ReportService();
